<template>
  <div class="d-flex align-center">
    <!-- <div class="mr-4">
      <CustomerAvatar :name="value.name" />
    </div> -->
    <div>
      {{ value.name }} <br />
      <small>{{ value.owner.user.name }}</small>
      <br />
      <small class="text--secondary"> {{ value.owner.user.email }}</small>
    </div>
  </div>
</template>

<script>
import CustomerAvatar from "@/components/customers/sections/CustomerAvatar.vue";

export default {
  components: {
    CustomerAvatar,
  },
  props: {
    value: {},
  },
};
</script>

<style>
</style>