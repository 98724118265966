<template>
  <v-btn v-bind="$attrs" icon @click="send()">
    <v-icon>mdi-whatsapp</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    phone: {},
  },

  computed : {
    unmaskedNumber() {
      return this.phone.replace(/[^0-9]/g, '');
    }
  },

  methods: {
    send() {
      window.open(`https://api.whatsapp.com/send?phone=${this.unmaskedNumber}`, '_blank');
    },
  },
};
</script>

<style>
</style>