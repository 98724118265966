<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col>
            <app-select
              :items="plans"
              v-model="form.plan"
              :item-text="
                (item) =>
                  `${item.name} - ${item.type} - ${
                    item.status
                  } - ${$format.moneyBr(item.value)} - ${item.vendor}`
              "
              return-object
              label="Plano"
              @change="setEndsAt()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-text-field
              label="Data de Inicio"
              type="date"
              v-model="form.start_at"
            />
          </v-col>
          <v-col>
            <app-text-field
              label="Data de Termino"
              type="date"
              v-model="form.ends_at"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-select
              label="Método de Pagamento"
              :items="vendors"
              v-model="form.vendor"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "date-fns/format";
import { addDays } from "date-fns";

export default {
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      plans: [],
      baseForm: {
        plan: {},
        start_at: format(new Date(), "yyyy-MM-dd"),
        ends_at: null,
        company: {},
      },
      form: {
        plan: {},
      },
      vendors: [
        { text: "Pagar.Me", value: "pagarme" },
        { text: "PagSeguro", value: "pagseguro" },
        { text: "PIX", value: "pix" },
        { text: "Stripe", value: "stripe" },
        { text: "Efi", value: "efi" },
      ],
    };
  },

  computed: {
    title() {
      return this.form.id ? "Editar Assinatura" : "Nova Assinatura";
    },
  },

  methods: {
    async open(subscription_id) {
      this.reset();
      this.getPlans();
      if (subscription_id) {
        await this.showSubscription(subscription_id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.form.company = this.company;
    },

    async showSubscription(subscription_id) {
      await this.$http
        .show("subscription/admin/manual-subscription", subscription_id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.subscription));
          this.form.start_at = this.$format.dateBr(
            this.form.start_at,
            "yyyy-MM-dd"
          );
          this.form.next_renewal_date = this.$format.dateBr(
            this.form.next_renewal_date,
            "yyyy-MM-dd"
          );
          this.form.ends_at = this.$format.dateBr(
            this.form.ends_at,
            "yyyy-MM-dd"
          );
        })
        .catch((error) => {});
    },

    getPlans() {
      this.$loading.start();
      this.$http
        .index("subscription/plans")
        .then((response) => {
          this.plans = response.plans;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSave() {
      this.$loading.start();
      if (this.form.id) {
        this.update();
      } else {
        this.store();
      }
    },

    store() {
      this.$http
        .store("subscription/admin/manual-subscription", this.form)
        .then((response) => {
          this.processSave();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    update() {
      this.$http
        .update(
          "subscription/admin/manual-subscription",
          this.form.id,
          this.form
        )
        .then((response) => {
          this.processSave();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSave() {
      this.$emit("store", this.form.company.id);
      this.dialog = false;
      this.$loading.finish();
    },

    // handleSave() {
    //   this.$loading.start();
    //   this.$http
    //     .store("subscription/admin/manual-subscription", this.form)
    //     .then((response) => {
    //       this.$emit("store", this.form.company.id);
    //       this.$loading.finish();
    //       this.dialog = false;
    //     })
    //     .catch((error) => {
    //       this.$loading.finish();
    //     });
    // },
    setEndsAt() {
      this.form.ends_at = format(
        addDays(new Date(), this.form.plan.days),
        "yyyy-MM-dd"
      );
    },
  },
};
</script>

<style>
</style>